<template>
  <div>
    <div class="card border-primary mb-3" style="width: 700px">
      <h6 class="card-header border-primary bg-primary text-white p-2">
        ตัวเลือกการค้นหา
      </h6>
      <div class="card-body">
        <div class="d-flex flex-row">
          <div class="my-auto mr-2">วันที่:</div>
          <div class="mr-2">
            <b-form-datepicker
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="th"
              v-model="date"
              placeholder="เลือกวัน"
              label-no-date-selected="ยังไม่เลือก"
              today-button
              reset-button
              close-button
              label-close-button="ปิด"
              label-reset-button="รีเซ็ต"
              label-today="วันนี้"
              label-today-button="วันนี้"
            ></b-form-datepicker>
          </div>
          <button class="btn btn-primary" @click="loadData">ค้นหา</button>
        </div>
      </div>
    </div>
    <div class="card border-primary">
      <h5 class="card-header border-primary bg-primary text-white">
        งวดหวยตามวัน
      </h5>
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-bordered table-hover table-dark text-center"
          >
            <thead>
              <tr>
                <th>#ID</th>
                <th>ชื่อหวย</th>
                <th>รางวัลที่1</th>
                <th>รางวัลที่2</th>
                <th>เวลาออกผล</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(lottoRound, index) in lottoRounds" :key="index">
                <td>{{ lottoRound.id }}</td>
                <td>{{ lottoRound.name }}</td>
                <td>{{ lottoRound.result }}</td>
                <td>{{ lottoRound.result2nd }}</td>
                <td>
                  {{ $date(lottoRound.closeDate).format("DD/MM/YYYY HH:mm") }}
                </td>
                <td>
                  <router-link
                    class="btn btn-primary"
                    :to="{
                      name: 'LottoRoundForm',
                      params: { id: lottoRound.id },
                    }"
                    >ออกผล</router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: this.$date().format("YYYY-MM-DD"),
      lottoRounds: {},
    };
  },
  watch: {
    async date() {
      await this.loadData();
    },
  },
  methods: {
    async loadData() {
      this.swal.processing();
      try {
        let res = await this.axios({
          method: "get",
          url: "lotto/round",
          params: {
            closeDate: this.date,
          },
        });

        this.lottoRounds = res.data;

        this.swal.close();
      } catch (e) {
        console.log(e);
        this.swal.swalError();
      }
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>